import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
// import urljoin from 'url-join';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import Sticky from 'react-stickynode';
import {
//  tablecss,
//  bar,
//  headerleft,
//  headerno,
//  headertitle,
//  headertitle2,
//  headerright,
//  button,csstabgrid,
//  csstabgridinner,
//  csstabgridchild1,
//  csstabgridchild2,
//  csstabgridchild3,
//  csstabgridchild4,
//  csstabgridchild5,
//  dis2,
 cityset,
//  syousaiheader,
//  csssyousaigridinner,
//  csssyousaigridchild000,
//  table,
//  row1,
//  row2,
//  tablediv,
 cssgrid,
 panel,
 cssgridchild,
//  taglinks,
//  tagbutton,
 stickybar,
 tabmenu,
 datasyousai,
 datakanren } from "./blog-post.module.css"

import CesiumContainer from "../components/cesium/cesiumContainer50_c_l"
import CesiumContainer2 from "../components/cesium/cesiumContainer50_c_r"



// import Image from '../components/image';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};




const BlogPostTemplate = (props: any) => {

  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );

  const post = props.data.markdownRemark;


  const kml_filenfd = post.frontmatter.kml_file.normalize( 'NFD' ) ;

  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  const city1 = {value};
  const city1nfd = city1.value.normalize( 'NFD' ) ;
  const csv1='/csv/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-4)
  const kml_kmz1='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd;
  const kml_kml1='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'

  const city2 = {value2};
  const city2nfd = city2.value2.normalize( 'NFD' ) ;
  const csv2='/csv/'+post.frontmatter.no+'/'+ city2nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-4)
  const kml_kmz2='/kml/'+post.frontmatter.no+'/'+ city2nfd + '_' + kml_filenfd
  const kml_kml2='/kml/'+post.frontmatter.no+'/'+ city2nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'


  // const disqusConfig = {
  //   shortname: process.env.GATSBY_DISQUS_NAME,
  //   config: { identifier: slug, title },
  // };

  var filename="sdg_"+post.frontmatter.sdgs_no+".png";



  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

<Sticky innerZ={9999} activeClass="nav-sticky">
      <div className={stickybar}>
          <div>
            <div>
              <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}>
                <img src={"/sdgsicon/sdg_"+post.frontmatter.sdgs_no+".png"}
                style={{width:"5vh"}}
                />
              </a>
              {post.frontmatter.no}
            </div>
          </div>
          <div>
            {post.frontmatter.title}
          </div>
          <div>
            <span style={{fontSize:"smaller"}}>
            {post.frontmatter.title_height_name+"×"+post.frontmatter.title_color_name}
            </span>
          </div>
          <div>
            <div>
              <a href="#" style={{fontSize:"small"}}>MENU</a>
            </div>
          </div>
      </div>
    </Sticky>



    <div className={tabmenu}>
      <a href={"/"+post.frontmatter.no+"/a2/#t"}>
        <div>
          自分で地域を選ぶ
        </div>
      </a>
      <a href={"/"+post.frontmatter.no+"/b2/#t"}>
        <div>
          地域の昔と比べる
        </div>
      </a>
      <a href={"/"+post.frontmatter.no+"/c2/#t"}>
        <div>
          他の地域と比べる
        </div>
      </a>
      <a href={"/"+post.frontmatter.no+"/d2/#t"}>
        <div>
          典型例とその解説
        </div>
      </a>
      <a href={"/"+post.frontmatter.no+"/e2/#t"}>
        <div>
          国土全体で見る
        </div>
      </a>
    </div>



{/*  
      <div className={csstabgrid}>
      <div className={csstabgridinner}>
        <div className={csstabgridchild1}>
          <Link to={"/"+post.frontmatter.no+"/a2/#t"} className={dis2}>
            <h3>自分で地域を選ぶ</h3>
          </Link>
        </div>
        <div className={csstabgridchild2}>
          <Link to={"/"+post.frontmatter.no+"/b/#t"} className={dis2}>
            <h3>地域の昔と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild3}>
          <Link to={"/"+post.frontmatter.no+"/c/#t"} className={dis2}>
            <h3>他の地域と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild4}>
          <Link to={"/"+post.frontmatter.no+"/d/#t"} className={dis2}>
            <h3>典型例とその解説</h3>
          </Link>
        </div>
        <div className={csstabgridchild5}>
          <Link to={"/"+post.frontmatter.no+"/e/#t"} className={dis2}>
            <h3>国土全体で見る</h3>
          </Link>
        </div>
      </div>
    </div> */}






<div>
  <div className={cssgrid}>
    <div className={panel}>
      <div>
        <div>
          </div>
            <div className={cssgridchild}>

              <div className={cityset}>
                {/* <Cityset1
                    kml_kmz={kml_kmz1}
                    /> */}
                <CesiumContainer
                width="100%"
                height="80vh"
                margintop="0"
                marginbottom="0"
                marginleft="0"
                margintopbartop="0"
                margintopbarbottom="0"
                margintopbarleft="0"
                topbarwidth="100%"
                marginbuttontop1="-10vh"
                marginbuttontop2="0vh"
                marginbottombutton3left="50vw"
                marginbottombutton4left="75vw"
                  kml_kml={kml_kml1}
                  kml_kmz={kml_kmz1}
                  // dir="/kml/sample.kml"
                  no={post.frontmatter.no}
                  full_name="全画面表示"
                  tofull_url={"/"+post.frontmatter.no+"/f"}
                  full_url={"/"+post.frontmatter.no+"/f"}
                  back_url={"/"+post.frontmatter.no+"/a2/#t"}
                  year1={post.frontmatter.year1}
                  year2={post.frontmatter.year2}
                  year3={post.frontmatter.year3}
                  year4={post.frontmatter.year4}
                  year5={post.frontmatter.year5}
                  csv={csv1}
                  csvmode={post.frontmatter.csvmode}
                  min={post.frontmatter.min}
                  ratio={post.frontmatter.ratio}
                  shikii1={post.frontmatter.shikii1}
                  shikii2={post.frontmatter.shikii2}
                  shikii3={post.frontmatter.shikii3}
                  hanrei1_color={post.frontmatter.hanrei1_color}
                  hanrei2_color={post.frontmatter.hanrei2_color}
                  hanrei3_color={post.frontmatter.hanrei3_color}
                  hanrei4_color={post.frontmatter.hanrei4_color}
                  kml1_color={post.frontmatter.kml1_color}
                  kml2_color={post.frontmatter.kml2_color}
                  kml3_color={post.frontmatter.kml3_color}
                  kml4_color={post.frontmatter.kml4_color}
                  title={post.frontmatter.title}
                  cityname={city1nfd}
                />
              </div>
            </div>
          </div>
        </div>

          <div className={panel}>
            <div>
              <div>
                </div>
                  <div className={cssgridchild}>

            <CesiumContainer2
                width="100%"
                height="80vh"
                margintop="0"
                marginbottom="0"
                marginleft="0"
                margintopbartop="0"
                margintopbarbottom="0"
                margintopbarleft="0"
                topbarwidth="100%"
                marginbuttontop1="-10vh"
                marginbuttontop2="0vh"
                marginbottombutton3left="50vw"
                marginbottombutton4left="75vw"
                  kml_kml={kml_kml2}
                  kml_kmz={kml_kmz2}
                  // dir="/kml/sample.kml"
                  no={post.frontmatter.no}
                  full_name="全画面表示"
                  tofull_url={"/"+post.frontmatter.no+"/f"}
                  full_url={"/"+post.frontmatter.no+"/f"}
                  back_url={"/"+post.frontmatter.no+"/a2/#t"}
                  year1={post.frontmatter.year1}
                  year2={post.frontmatter.year2}
                  year3={post.frontmatter.year3}
                  year4={post.frontmatter.year4}
                  year5={post.frontmatter.year5}
                  csv={csv2}
                  csvmode={post.frontmatter.csvmode}
                  min={post.frontmatter.min}
                  ratio={post.frontmatter.ratio}
                  shikii1={post.frontmatter.shikii1}
                  shikii2={post.frontmatter.shikii2}
                  shikii3={post.frontmatter.shikii3}
                  hanrei1_color={post.frontmatter.hanrei1_color}
                  hanrei2_color={post.frontmatter.hanrei2_color}
                  hanrei3_color={post.frontmatter.hanrei3_color}
                  hanrei4_color={post.frontmatter.hanrei4_color}
                  kml1_color={post.frontmatter.kml1_color}
                  kml2_color={post.frontmatter.kml2_color}
                  kml3_color={post.frontmatter.kml3_color}
                  kml4_color={post.frontmatter.kml4_color}
                  title={post.frontmatter.title}
              cityname={city2nfd}
        
            />
            </div>
        </div>
      </div>

      </div>



</div>


<div id="s" style={{marginTop:"-12vh"}}></div>
<div style={{marginTop:"14vh"}}></div>


<div className={datasyousai}>
  <details>
    <summary>
      データの詳細情報を確認する
    </summary>
      <div>
        <table>
          <caption>高さ</caption>
          {/* <thead>
            <tr>
              <th></th>
              <th>解説</th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
            <td>名称</td>
            <td>{post.frontmatter.title_height_name}</td>
            </tr>
            <tr>
            <td>凡例名</td>
            <td>{post.frontmatter.hanrei_height_name}</td>
            </tr>
            <tr>
            <td>年次</td>
            <td>{post.frontmatter.year_display}</td>
            </tr>
            <tr>
            <td>出典</td>
            <td>{post.frontmatter.height_origin}</td>
            </tr>
            <tr>
            <td>定義</td>
            <td>{post.frontmatter.height_origin_note}</td>
            </tr>
            <tr>
            <td>詳細</td>
            <td>
              <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
              <br/>
              <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
              <br/>
              <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
              <br/>
              <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
            </td>
            </tr>
          </tbody>
        </table>

        <table>
          <caption>色</caption>

            {/* <thead>
            <tr>
            <th className={row1}></th>
            <th className={row2}>解説</th>
            </tr>
            </thead> */}
            <tbody>
            <tr>
            <td>名称</td>
            <td>{post.frontmatter.title_color_name}</td>
            </tr>
            <tr>
            <td>凡例名</td>
            <td>{post.frontmatter.hanrei_color_name}</td>
            </tr>
            <tr>
            <td>年次</td>
            <td>{post.frontmatter.year_display}</td>
            </tr>
            <tr>
            <td>出典</td>
            <td>{post.frontmatter.color_origin}</td>
            </tr>
            <tr>
            <td>定義</td>
            <td>{post.frontmatter.color_origin_note}</td>
            </tr>
            <tr>
            <td>詳細</td>
            <td>
              <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
              <br/>
              <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
              <br/>
              <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
              <br/>
              <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
        {/* <div>
      <table>
        <tbody>
          <tr>
            <td style={{width:"35%"}}>csv（メッシュコード,高さ,色）</td>
            <td>
              <a href={csv+"_"+post.frontmatter.year5+".csv"}>{post.frontmatter.year1}</a>
              <a href={csv+"_"+post.frontmatter.year5+".csv"}>{post.frontmatter.year2}</a>
              <a href={csv+"_"+post.frontmatter.year5+".csv"}>{post.frontmatter.year3}</a>
              <a href={csv+"_"+post.frontmatter.year5+".csv"}>{post.frontmatter.year4}</a>
              <a href={csv+"_"+post.frontmatter.year5+".csv"}>{post.frontmatter.year5}</a>
            </td>
            </tr>
        </tbody>
      </table>
    </div> */}

  </details>
</div>


<div className={datakanren}>
<details>
  <summary>
    関連するデータを調べる
  </summary>
    {post.frontmatter.tags == null ? null : (
    <div>
      {post.frontmatter.tags.map((tag: string, index: number) => (
      <div key={index}>
        <a
        key={index}
        href={`/tags/${_.kebabCase(tag)}/`}
        >
          {`${tag}`}
        </a>
      </div>
      ))}
    </div>
    )}
</details>
</div>

    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query GeoC2($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
title_height_name
hanrei_height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
title_color_name
hanrei_color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
        year1
        year2
        year3
        year4
        year5
        try
        min
        ratio
        shikii1
        shikii2
        shikii3
        csvmode
        kml1_color
        kml2_color
        kml3_color
        kml4_color

      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
title_height_name
hanrei_height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
title_color_name
hanrei_color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
            year1
            year2
            year3
            year4
            year5
            try
            min
            ratio
            shikii1
            shikii2
            shikii3
            csvmode
            kml1_color
            kml2_color
            kml3_color
            kml4_color
    
          }
        }
      }
    }
  }
`;

